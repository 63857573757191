
  export default {
    name: 'InstagramFeed1',
    data() {
      return {
        loading: true,
        info: null,
      };
    },
    props: {
      componentId: {
        type: String,
        required: true,
      },
      container: {
        type: Object,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
      photos: {
        type: Object,
        required: true,
      },
      limit: {
        type: String,
      },
    },
    async mounted() {
      try {
        this.info = await this.$api.instagram.getProfile(this.componentId);
      } catch (e) {}
      this.loading = false;
    },
    computed: {
      mediaList() {
        return (this.info.media.data || []).slice(0, this.limit || 10);
      },
    },
    methods: {
      goToProfile() {
        this.goToPage(`/${this.info.profile.username}/`);
      },
      goToMedia(shortcode) {
        this.goToPage(`/p/${shortcode}/`);
      },
      goToPage(page) {
        window.open(`https://www.instagram.com${page}`, '_blank');
      },
    },
  };
